<template>
  <div class="container nota-print">

    <div class="header hide-print">
      <b-button type="is-primary" icon-left="printer-outline" @click="print">Print</b-button>
    </div>

    <div class="content" v-if="loaded && data">

      <p class="nota-title">NOTA PEMBELIAN</p>

      <div>
        <div class="is-flex is-justify-content-space-between">
          <div>
            <img src="/img/sgara-logo.png" alt="" class="logo">
            <p>PT Transformasi Digital Laut</p>
            <p>Jl. Setia No. 17, Bekasi 17116</p>
            <p>Email jason@sgara.id</p>
          </div>

          <div class="content-header-info">
            <div class="is-flex">
              <p class="has-text-weight-bold">Nota Hasil Panen</p>
              <p>No. #{{ data.nota_panen_id }}</p>
            </div>
            <div class="is-flex">
              <p>Nama Petambak</p>
              <p>{{ data.farmer_name }}</p>
            </div>
            <div class="is-flex">
              <p>Lokasi Tambak</p>
              <p>{{ data.farmer_location }}</p>
            </div>
            <div class="is-flex">
              <p>Nama Staf</p>
              <p>{{ data.staff_name }}</p>
            </div>
            <div class="is-flex">
              <p>Tanggal Panen</p>
              <p>{{ $dateFormat(data.harvest_date, 'DD MMMM YYYY') }}</p>
            </div>
          </div>
        </div>
        <div class="b-table mt-4 mb-4" v-if="data">
          <div class="table-wrapper">
            <table class="table is-bordered is-narrow">
              <thead>
              <tr>
                <th>Nama Petak</th>
                <th>Size</th>
                <th>Harga/Kg</th>
                <th>Quantity (Kg)</th>
                <th>Jumlah</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(crop, key) in data.crops" :key="key">
                <td>{{ crop.name }}</td>
                <td>{{ crop.size }}</td>
                <td>{{ $currency(crop.price_per_kg) }}</td>
                <td>{{ crop.qty }}</td>
                <td>{{ $currency(crop.price_per_kg * crop.qty) }}</td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td class="border-0"></td>
                <td class="border-0"></td>
                <th class="border-0">Total</th>
                <td class="border-top-0">{{ totalQty }}</td>
                <td class="border-top-0">{{ $currency(totalPrice) }}</td>
              </tr>
              </tfoot>
            </table>
          </div>

          <p class="invoice-payment-date" v-if="data.payment_method === 'Tempo Pembayaran'">
            *Pembayaran akan dilakukan tanggal <span class="has-text-weight-bold">{{ $dateFormat(data.payment_date || data.harvest_date, 'DD MMMM YYYY') }}</span>
          </p>
        </div>
<!--        <div class="is-flex is-align-items-center is-justify-content-space-between" style="margin: 1rem 5rem 0 5rem">-->
<!--          <div class="signature">-->
<!--            Pemilik Tambak-->
<!--            <img :src="data.farmer_sign" alt="" v-if="data.farmer_sign">-->
<!--            <div v-else></div>-->
<!--          </div>-->
<!--          <div class="signature">-->
<!--            Staf Panen-->
<!--            <img :src="data.staff_sign" alt="" v-if="data.staff_sign">-->
<!--            <div v-else></div>-->
<!--          </div>-->
<!--        </div>-->
      </div>

    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'NotaHasilPanenPrintInvoice',
  data: () => {
    return {
      data: null,
      loaded: false,
    }
  },
  computed: {
    nota_panen_id() {
      return this.$route.params.nota_panen_id
    },

    totalPrice () {
      return this.data.crops.reduce((a,b) => a + (b.qty * b.price_per_kg), 0)
    },

    totalQty () {
      return this.$number(this.data.crops.reduce((a,b) => a + b.qty, 0))
    },
  },
  created() {
    document.querySelector('html').classList.add('has-background-white')
    this.loadData()
  },
  methods: {
    async print() {
      let hidePrint = document.querySelectorAll('.hide-print')
      for (let i = 0; i < hidePrint.length; i++) {
        hidePrint[i].style.display = 'none'
      }
      window.print()
      for (let i = 0; i < hidePrint.length; i++) {
        hidePrint[i].style.display = ''
      }
    },

    ...mapActions('supplyChain', [
      'getNotaPanenByID'
    ]),

    loadData() {
      this.$loading()
      this.getNotaPanenByID(this.nota_panen_id).then(res => {
        if (res.data) this.data = res.data
      }).finally(() => {
        this.loaded = true
        this.$loading(false)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.nota-print {
  font-family: Arial,sans-serif;
  padding: 2rem 0;

  .header {
    text-align: right;
    padding-bottom: 2rem;
  }

  .content {
    .logo {
      display: flex;
      height: 48px;
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 0
    }

    .nota-title {
      font-weight: bold;
      font-size: 42px;
      text-align: center;
      margin-bottom: .65rem;
    }

    .content-header-info {
      > div {
        > p {
          &:first-child {
            width: 180px;
          }

          &:nth-child(2) {
            width: 300px;
          }
        }
      }
    }

    .table {
      font-size: .86rem;
    }
  }

  .signature {
    display: flex;
    flex-direction: column;

    div, img {
      height: 150px;
      width: 300px;
      object-fit: contain;
      border-bottom: 1px solid rgba(0, 0, 0, .4);
    }
  }

  .invoice-payment-date {
    margin-top: 1rem;
    font-size: .9rem;
    font-style: italic;
    text-align: right;
    color: rgba(0,0,0,.5);
  }
}

.border-0 {
  border: unset;
}

@page {
  size: A4 landscape;
}

@media print {
  @page {
    size: A4 landscape;
  }

  .pagebreak {
    clear: both;
    page-break-before: always;
  }

  html, body {
    width: 297mm;
    height: 210mm;
  }

  * {
    -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/
  }

  svg, .avoid-break {
    page-break-inside: avoid;
    break-inside: avoid;
    page-break-after: avoid;
    page-break-before: avoid;
  }
}

</style>
